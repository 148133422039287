/* @font-face {
  font-family: "light";
  src: local("Poppins"),
    url("./fonts/Poppins-Light.woff2") format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "reguler";
  src: local("Poppins"),
    url("./fonts/Poppins-Reguler.woff2") format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "medium";
  src: local("Poppins"),
    url("./fonts/Poppins-Medium.woff2") format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "semiBold";
  src: local("Poppins"),
    url("./fonts/Poppins-SemiBold.woff2") format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: "bold";
  src: local("Poppins"),
    url("./fonts/Poppins-Bold.woff2") format("woff2");
  font-weight: 700;
} */

html::-webkit-scrollbar {
  width: 10px;
  position: fixed;
  right: 0;
  bottom: 0;
}

html::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid transparent;
  background-clip: content-box;
  background-color: #48988D;
}

body {
  /* padding: 0 45px; */
  background-color: #fcfcfc;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  border: none;
}

@keyframes moveUp {
  from {
    bottom: -50px;
  }
  to {
    bottom: 100%;
  }
}
